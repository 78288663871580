
.admin-list-head, .admin-list-body {
    display: flex;
    width: 100%;
    padding-left: 15px;

}

.admin-list-head li {
    display: flex;
    width: 100%;
    background-color: rgb(252, 239, 223);
    padding: 10px 30px;
    margin-top: 50px;
}

.admin-list-head li p{
    font-weight: 600;
}

.admin-list-body li {
    display: flex;
    width: 100%;
    background-color: rgb(244, 243, 243);
    padding: 12px 30px;
    align-items: center;
    border-bottom: 1px solid #e0e0e0;
}

.admin-list-body li p{
    font-weight: 600;
}

.admin-edit-btn p {
   background-color: rgb(36, 36, 36);
   padding: 10px 30px;
   border-radius: 10px;
   color: aliceblue;
   cursor: pointer;
} 

.admin-edit-btn p:hover {
    background-color: rgb(247, 245, 245);
    padding: 10px 30px;
    border-radius: 10px;
    color: rgb(30, 31, 31);
    border: 1px solid black;
 } 

 .admin-create-btn {
    background-color: rgb(247, 245, 245);
    padding: 10px 30px;
    border-radius: 10px;
    color: rgb(30, 31, 31);
    cursor: pointer;
    border: 1px solid black;
 }

 .admin-create-btn:hover {
    opacity: 0.5;
 }

 .admin-inputs-wrap input {
    outline: none;
    border: none;
    padding: 20px 10px;
    font-size: 15px;
    background-color: rgb(240, 240, 240);
    font-weight: 500;
    margin-bottom: 10px;

 }